import React from "react";
import propTypes from "prop-types";
import { map } from "lodash/fp";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const ChannelsCarousel = ({ loading, channels }) => {
  return (
    <Carousel
      autoPlay={true}
      infiniteLoop={true}
      interval={6000}
      centerMode={true}
      centerSlidePercentage={60}
      emulateTouch={true}
      swipeable={true}
      showIndicators={false}
      className="channels-carousel"
      showThumbs={false}
      key={channels ? channels.length : 0}
    >
      {map.convert({ cap: false })(
        ({ thumbnail, slug, descripcion_corta, display_name }, k) => (
          <div
            className="channels-carousel-item"
            key={k}
            onClick={() => (document.location.href = `/${slug}`)}
          >
            <div className="channels-carousel-item-container">
              <div className="channels-carousel-item-container-image">
                <img
                  className="channels-carousel-item-container-image-img mobile"
                  src={thumbnail}
                  alt={slug}
                />
                <img
                  className="channels-carousel-item-container-image-img desktop"
                  src={thumbnail}
                  alt={slug}
                />
              </div>
              <div className="channels-carousel-item-container-content">
                <div className="channels-carousel-item-container-content-actions-info">
                  <h4 className="channels-carousel-item-container-content-name">
                    {display_name}
                  </h4>
                  <p className="channels-carousel-item-container-content-desc">
                    {descripcion_corta}
                  </p>
                </div>
                <div className="channels-carousel-item-container-content-actions"></div>
              </div>
            </div>
          </div>
        )
      )(channels)}
    </Carousel>
  );
};

ChannelsCarousel.propTypes = {
  loading: propTypes.bool,
  channels: propTypes.array,
};

export default ChannelsCarousel;
