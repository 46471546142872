import React, { useContext, useState } from "react";
import {
  Container,
  Table,
  TableCell,
  Segment,
  Button,
} from "semantic-ui-react";
import { PathRoutes, Texts } from "../../data/Data";
import { useManager, useManagerUpdate } from "../../data/api/useManager";
import AccountForm from "../../components/AccountForm";
import AccountManagerForm from "../../data/forms/AccountManagerForm";
import { Link } from "react-router-dom";
import { sessionContext } from "../../App";
import _ from "lodash/fp";
import MessageModal from "../../components/MessageModal";
import AccountWallet from "../../components/AccountWallet";

const AccountManager = ({ navigationSelected }) => {
  const { alias: id, type: userType } = useContext(sessionContext);
  const { manager, channels } = useManager(id);
  const { trigger } = useManagerUpdate(manager.id);
  const [open, setOpen] = useState(false);

  const isAllowed = () => {
    return userType === "user"
      ? (window.location.href = PathRoutes.PATH_ACCOUNT_USER)
      : null;
  };
  isAllowed();

  const handleOnSubmit = (values) => {
    trigger(values).then((response) => {
      setOpen(true);
    });
  };

  return userType !== "manager" ? null : (
    <Container fluid className="account-manager">
      <h2>{Texts.account.manager.title}</h2>
      {navigationSelected === "profile" ? (
        <Segment className="account-manager-info">
          <h3>{Texts.account.manager.info.title}</h3>
          <AccountForm
            name="account-manager"
            formFields={AccountManagerForm}
            values={manager}
            onSubmit={handleOnSubmit}
            texts={Texts.account.manager.info}
          />
        </Segment>
      ) : null}
      <MessageModal
        open={open}
        texts={Texts.account.manager.messages.success}
        action={() => window.location.reload()}
        onClose={() => window.location.reload()}
      />
      {navigationSelected === "channels" ? (
        <Segment className="account-manager-channels">
          <h3>{Texts.account.manager.channels.title}</h3>
          <div className="account-manager-channels-table">
            <Button primary href={PathRoutes.PATH_ACCOUNT_CHANNEL_NEW}>
              {Texts.account.manager.channels.new}
            </Button>
            <Table>
              <Table.Header>
                <Table.Row>
                  <TableCell>
                    {Texts.account.manager.channels.tableHeaders.channel}
                  </TableCell>
                  <TableCell>
                    {Texts.account.manager.channels.tableHeaders.premium}
                  </TableCell>
                  <TableCell>
                    {Texts.account.manager.channels.tableHeaders.last_update}
                  </TableCell>
                  <TableCell>
                    {Texts.account.manager.channels.tableHeaders.favs}
                  </TableCell>
                  <TableCell>
                    {Texts.account.manager.channels.tableHeaders.subs}
                  </TableCell>
                  <TableCell></TableCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.map(
                  ({
                    slug,
                    total_follows,
                    total_subs,
                    fecha_alta,
                    ultima_modificacion,
                  }) => {
                    const date = new Date(ultima_modificacion || fecha_alta);
                    const options = {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    };
                    return !slug ? null : (
                      <Table.Row>
                        <TableCell>{slug}</TableCell>
                        <TableCell>No</TableCell>
                        <TableCell>
                          {date.toLocaleDateString(navigator.language, options)}
                        </TableCell>
                        <TableCell>{total_follows}</TableCell>
                        <TableCell>{total_subs}</TableCell>
                        <TableCell>
                          <Link
                            to={`${PathRoutes.PATH_ACCOUNT_MANAGER}/${slug}`}
                          >
                            <Button primary>
                              {Texts.account.manager.channels.tableBody.edit}
                            </Button>
                          </Link>
                        </TableCell>
                      </Table.Row>
                    );
                  }
                )(channels)}
              </Table.Body>
            </Table>
          </div>
        </Segment>
      ) : null}
      {navigationSelected === "wallet" ? <AccountWallet alias={id} /> : null}
    </Container>
  );
};

export default AccountManager;
