import React, { useState } from "react";
import PropTypes from "prop-types";
import { Texts } from "../data/Texts";
import {
  Button,
  Header,
  Icon,
  Image,
  Modal,
  ModalActions,
  ModalContent,
  ModalDescription,
  ModalHeader,
  Popup,
} from "semantic-ui-react";
import { useUserBalance } from "../data/api/useCredits";
import { PathRoutes } from "../data/PathRoutes";
import { Link } from "react-router-dom";
import { useUserAction } from "../data/api/useUser";

const SubscribeButton = ({
  channel: { channel, name, subs, thumbnail },
  user: { alias },
}) => {
  const [suscribeModalStatus, setSuscribeModalStatus] = useState(false);
  const [creditsModalStatus, setCreditsModalStatus] = useState(false);
  const [autorenewalModalStatus, setAutorenewalModalStatus] = useState(false);
  const { trigger } = useUserBalance(alias);
  const { trigger: triggerSubscribe } = useUserAction();

  const subscribeModal = () => {
    trigger().then((response) =>
      response && response > 0
        ? setSuscribeModalStatus(true)
        : setCreditsModalStatus(true)
    );
  };

  const doSubscribe = () =>
    triggerSubscribe({
      user: alias,
      action: "subscription",
      status: true,
      channel: channel,
    }).then(() => {
      setSuscribeModalStatus(false);
      setAutorenewalModalStatus(true);
    });

  return (
    <>
      <Popup
        content={Texts.subscribeButton.tooltip}
        trigger={
          <Button disabled={!alias} primary onClick={() => subscribeModal()}>
            <Icon name="star" />
            {subs || 0}
          </Button>
        }
      />
      <Modal
        open={suscribeModalStatus}
        onClose={() => setSuscribeModalStatus(false)}
        key="modal-subscription"
      >
        <ModalHeader>
          {Texts.subscribeButton.modal.subscribe.header}
        </ModalHeader>
        <ModalContent image>
          <Image size="small" src={thumbnail} wrapped />
          <ModalDescription>
            <Header>
              {Texts.subscribeButton.modal.subscribe.description.header.replace(
                "##__channel_alias__##",
                name
              )}
            </Header>
            <p>{Texts.subscribeButton.modal.subscribe.description.copy}</p>
            <p>{Texts.subscribeButton.modal.subscribe.description.copy2}</p>
          </ModalDescription>
        </ModalContent>
        <ModalActions>
          <Button
            content={Texts.subscribeButton.modal.subscribe.actions.cancel}
            onClick={() => setSuscribeModalStatus(false)}
          />
          <Button
            content={Texts.subscribeButton.modal.subscribe.actions.subscribe}
            labelPosition="left"
            icon="star"
            onClick={() => doSubscribe()}
            primary
          />
        </ModalActions>
      </Modal>
      <Modal
        open={creditsModalStatus}
        onClose={() => setCreditsModalStatus(false)}
        key="modal-credits"
      >
        <ModalHeader>{Texts.subscribeButton.modal.credits.header}</ModalHeader>
        <ModalContent image>
          <Image size="small" src={thumbnail} wrapped />
          <ModalDescription>
            <Header>
              {Texts.subscribeButton.modal.credits.description.header}
            </Header>
            <p>
              {Texts.subscribeButton.modal.credits.description.copy.replace(
                "##__channel_alias__##",
                name
              )}
            </p>
            <p>{Texts.subscribeButton.modal.credits.description.copy2}</p>
          </ModalDescription>
        </ModalContent>
        <ModalActions>
          <Button
            content={Texts.subscribeButton.modal.credits.actions.cancel}
            onClick={() => setCreditsModalStatus(false)}
          />
          <Button
            content={Texts.subscribeButton.modal.credits.actions.buy}
            as={Link}
            to={PathRoutes.PATH_CREDITS}
            primary
          />
        </ModalActions>
      </Modal>
      <Modal
        open={autorenewalModalStatus}
        onClose={() => setAutorenewalModalStatus(false)}
        key="modal-autorenewal"
      >
        <ModalHeader>
          {Texts.subscribeButton.modal.autorenewal.header}
        </ModalHeader>
        <ModalContent image>
          <Image size="small" src={thumbnail} wrapped />
          <ModalDescription>
            <Header>
              {Texts.subscribeButton.modal.autorenewal.description.header.replace(
                "##__channel_alias__##",
                name
              )}
            </Header>
            <p>{Texts.subscribeButton.modal.autorenewal.description.copy}</p>
            <p>{Texts.subscribeButton.modal.autorenewal.description.copy2}</p>
            <p>{Texts.subscribeButton.modal.autorenewal.description.copy3}</p>
            <Link to={PathRoutes.PATH_ACCOUNT_USER}>
              {Texts.subscribeButton.modal.autorenewal.description.manage}
            </Link>
            <p className="modal-autorenewal-disclaimer">
              {Texts.subscribeButton.modal.autorenewal.description.disclaimer}
            </p>
          </ModalDescription>
        </ModalContent>
        <ModalActions>
          <Button
            content={Texts.subscribeButton.modal.autorenewal.actions.cancel}
            onClick={() => setAutorenewalModalStatus(false)}
          />
        </ModalActions>
      </Modal>
    </>
  );
};

SubscribeButton.propTypes = {
  channel: PropTypes.object,
  user: PropTypes.object,
};

export default SubscribeButton;
