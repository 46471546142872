import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { API_CREDITS_BUY, API_CREDITS_USER } from "./endpoints";
import _ from "lodash/fp";

export const useCreditsBuy = () => {
  const url = API_CREDITS_BUY;
  const fetcher = (url, payload) => {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((r) => {
      return r.json();
    });
  };
  return useSWRMutation(url, (url, { arg }) => {
    return fetcher(url, arg);
  });
};

export const useWallet = (alias) => {
  const url = API_CREDITS_USER.replace(":alias", alias);
  const fetcher = (url) => fetch(url).then((r) => r.json());
  return useSWR(url, fetcher);
};

export const useUserBalance = (alias) => {
  const url = API_CREDITS_USER.replace(":alias", alias);
  const fetcher = async (url) => {
    try {
      const response = await fetch(url);

      if (response.ok) {
        const responseData = await response.json();
        return responseData;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };
  return useSWRMutation(url, (url, { arg }) => {
    return fetcher(url, arg).then((response) => {
      return _.flow(_.last, _.get("balance"))(response);
    });
  });
};
