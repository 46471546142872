import React from "react";
import { Texts } from "../../data/Data";
import { SessionLoginContent } from "../../components/SessionLoginContent";

export const SessionLogin = ({ updateUserData }) => {
  return (
    <main className="session-login">
      <div className="session-login-heading">
        <h2 className="session-login-heading-title">
          {Texts.session.login.title}
        </h2>
      </div>
      <SessionLoginContent updateUserData={updateUserData} />
    </main>
  );
};

export default SessionLogin;
