import useSWRMutation from "swr/mutation";
import {
  API_MEDIA_DELETE,
  API_MEDIA_EDIT,
  API_MEDIA_UPLOAD,
  API_AUDIO_UPLOAD,
  API_AUDIO_EDIT,
} from "./endpoints";

export const useMediaUpload = (id) => {
  const url = id ? API_MEDIA_EDIT.replace(":id", id) : API_MEDIA_UPLOAD;
  const fetcher = async (url, payload) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        body: payload,
      });

      if (response.ok) {
        const responseData = await response.json();
        return responseData;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };
  return useSWRMutation(url, (url, { arg }) => {
    return fetcher(url, arg);
  });
};

export const useMediaDelete = () => {
  const url = API_MEDIA_DELETE;
  const fetcher = async (_url, payload) => {
    try {
      const url = API_MEDIA_DELETE.replace(":id", payload.id);
      const response = await fetch(url, {
        method: "GET",
      });

      if (response.ok) {
        const responseData = await response.json();
        return responseData;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };
  return useSWRMutation(url, (url, { arg }) => {
    return fetcher(url, arg);
  });
};

export const useAudioUpload = (id) => {
  const url = id ? API_AUDIO_EDIT.replace(":id", id) : API_AUDIO_UPLOAD;
  const fetcher = async (url, payload) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        body: payload,
      });

      if (response.ok) {
        const responseData = await response.json();
        return responseData;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };
  return useSWRMutation(url, (url, { arg }) => {
    return fetcher(url, arg);
  });
};
