import _ from "lodash/fp";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Imgs, PathRoutes, Texts } from "../../data/Data";
import { useSignup } from "../../data/api/useSession";
import SessionSignupForm, {
  SessionSignupManagerForm,
} from "../../data/forms/SessionSignup";
import SessionForm from "../../components/SessionForm";
import { Message } from "semantic-ui-react";
import MessageModal from "../../components/MessageModal";

const SessionSignup = ({ updateUserData }) => {
  const [showAliasErrorMessage, setShowAliasErrorMessage] = useState(false);
  const [showFormErrorMessage, setShowFormErrorMessage] = useState(false);
  const [aliasSuggestion, setAliasSuggestion] = useState("");
  const [loginFormValues, setLoginFormValues] = useState({
    name: "",
    surname: "",
    phone: "",
    phone2: "",
    whatsapp: "",
  });
  const [open, setOpen] = useState(false);
  const { trigger } = useSignup("manager");
  const SessionSignupFullManagerForm = _.flow(
    _.filter((field) => field.name !== "submit"),
    _.union(SessionSignupManagerForm)
  )(SessionSignupForm);

  useEffect(() => {
    const elements = document.querySelectorAll(
      ".session-signup-manager-content-block-highlights-list-item"
    );
    function showNextElement() {
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].classList.contains("show")) {
          elements[i].classList.remove("show");
          const nextIndex = (i + 1) % elements.length;
          elements[nextIndex].classList.add("show");
          break;
        }
      }
    }
    elements[0].classList.add("show");
    const intervalId = setInterval(showNextElement, 2000);
    return () => clearInterval(intervalId);
  }, []);

  const handleOnSubmit = (values) => {
    setShowAliasErrorMessage(false);
    setShowFormErrorMessage(false);
    setLoginFormValues(values);
    const fields = ["user", "alias", "password", "password_repeat"];
    const validForm = _.reduce(
      (valid, field) => (valid && _.get(field)(values) ? valid : false),
      true
    )(fields);
    if (!validForm) {
      setShowFormErrorMessage(true);
      return false;
    }
    trigger(values).then((response) => {
      _.cond([
        [
          ({ exists }) => exists === "yes",
          ({ alternative }) => {
            setAliasSuggestion(alternative);
            setShowAliasErrorMessage(true);
          },
        ],
        [
          _.stubTrue,
          () => {
            const { alias } = _.first(response);
            const objectToStorage = { type: "manager", alias };

            localStorage.setItem(
              "session-context",
              JSON.stringify(objectToStorage)
            );
            updateUserData(objectToStorage);

            setOpen(true);
          },
        ],
      ])(response);
    });
  };
  return (
    <main className="session-signup-manager">
      <div className="session-signup-manager-content">
        <div className="session-signup-manager-content-block">
          <div className="session-signup-manager-content-block-image">
            <img
              className="session-signup-manager-content-block-image-img"
              alt=""
              src={Imgs.session.img}
            />
          </div>
          <div className="session-signup-manager-content-block-highlights">
            <ul className="session-signup-manager-content-block-highlights-list">
              {_.map((highlight) => (
                <li
                  key={_.indexOf(highlight)(
                    Texts.session.signup.manager.highlights
                  )}
                  className="session-signup-manager-content-block-highlights-list-item"
                >
                  {highlight}
                </li>
              ))(Texts.session.signup.manager.highlights)}
            </ul>
          </div>
          <div className="session-signup-manager-content-block-features">
            <ul className="session-signup-manager-content-block-features-list">
              {_.map((feature) => (
                <li
                  key={_.indexOf(feature)(
                    Texts.session.signup.manager.features
                  )}
                  className="session-signup-manager-content-block-features-list-item"
                >
                  {feature}
                </li>
              ))(Texts.session.signup.manager.features)}
            </ul>
          </div>
        </div>
        <div className="session-signup-manager-content-block">
          <div className="session-signup-manager-content-block-title">
            <h4>{Texts.session.signup.manager.title}</h4>
          </div>
          <div className="session-signup-manager-content-block-signup">
            <h5>{Texts.session.signup.manager.signupTitle}</h5>
            {showAliasErrorMessage ? (
              <Message
                error
                header={Texts.session.signup.manager.messages.aliasError.header}
                list={_.map((item) =>
                  _.replace("##available__alias##", aliasSuggestion)(item)
                )(Texts.session.signup.manager.messages.aliasError.list)}
              />
            ) : null}
            {showFormErrorMessage ? (
              <Message
                error
                header={Texts.session.signup.manager.messages.formError.header}
                list={_.map((item) =>
                  _.replace("##available__alias##", aliasSuggestion)(item)
                )(Texts.session.signup.manager.messages.formError.list)}
              />
            ) : null}
            <SessionForm
              name="session-manager"
              formFields={SessionSignupFullManagerForm}
              values={{ ...loginFormValues, type: "manager" }}
              onSubmit={handleOnSubmit}
              texts={Texts.session.signup.manager}
            />
          </div>
        </div>
      </div>
      <MessageModal
        open={open}
        texts={Texts.session.signup.manager.messages.success}
        action={() =>
          (window.location.href = PathRoutes.PATH_SESSION_LOGIN_USER)
        }
        onClose={() =>
          (window.location.href = PathRoutes.PATH_SESSION_LOGIN_USER)
        }
      />
    </main>
  );
};

SessionSignup.propTypes = {
  type: PropTypes.string,
  updateUserData: PropTypes.func,
};

export default SessionSignup;
