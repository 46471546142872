export const ChannelVideo = {
  content: {
    info: {
      uploadDate: "Subido el",
    },
    actions: {
      subscribe: "Suscribirse",
      follow: "Seguir",
      notifications: "Recibir notificaciones",
      comments: "Comentarios",
      report: "Denunciar canal",
    },
  },
  relatedVideos: {
    title: "Vídeos Relacionados",
  },
};

export default ChannelVideo;
