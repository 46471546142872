import _ from "lodash/fp";
import React, { useState } from "react";
// import propTypes from "prop-types";
// import _ from "lodash/fp";
// import parse from "html-react-parser";
import { useChannels } from "../../data/api/useChannels";

import ChannelCardsWrapper from "../../components/ChannelCardsWrapper";
import ChannelsCarousel from "../../components/ChannelsCarousel";
import { API_URL } from "../../data/api/endpoints";

const Channels = (props) => {
  const [filters, setFilters] = useState(null);
  const { channels, isLoading } = useChannels(filters);

  const filterChannels = (filters) => {
    setFilters(filters);
  };

  const parsedChannels = _.map((channel) =>
    _.flow(({ pictures, thumbnail, topVertical, topHorizontal }) => {
      const parsedPictures = _.flow(
        _.split(";"),
        _.map((value) => _.trim(value)),
        _.filter((value) => !_.isEmpty(value)),
        _.map((value) => API_URL + value)
      )(pictures);

      const parsedFeedPictures = _.flow(
        _.mapValues((value) => _.trim(value)),
        _.pickBy((value) => !_.isEmpty(value)),
        _.mapValues((value) =>
          _.startsWith("http")(value) ? value : API_URL + value
        )
      )({ thumbnail, topVertical, topHorizontal });

      return {
        ...channel,
        ...parsedFeedPictures,
        pictures: parsedPictures,
      };
    })(channel)
  )(channels);

  return (
    <main className="channels">
      <ChannelsCarousel
        loading={isLoading}
        channels={_.filter(({ in_top }) => _.isEqual("1")(in_top))(
          parsedChannels
        )}
      />
      <ChannelCardsWrapper
        loading={isLoading}
        channels={parsedChannels}
        filterChannels={filterChannels}
      />
    </main>
  );
};

Channels.propTypes = {};

export default Channels;
