export const SessionSignup = {
  title: "Estás a un paso de vivir la auténtica VIDALOCA",
  blockTitle: "Descubre tu yo desconocido",
  signupTitle: "Regístrate como usuario",
  form: {
    alias: { label: "Alias Usuario" },
    user: { label: "Email Usuario" },
    password: { label: "Contraseña" },
    password_repeat: { label: "Repite Contraseña" },
    submit: { label: "Enviar" },
  },
  messages: {
    aliasError: {
      header: "El alias ya está en uso",
      list: [
        "Prueba con otro alias distinto",
        "##available__alias## está disponible",
      ],
    },
    formError: {
      header: "Error en el formulario",
      list: [
        "Comprueba que los datos están rellenos",
        "Comprueba que los datos son correctos",
      ],
    },
    success: {
      header: "Cuenta creada",
      content: [
        "Tu cuenta se ha creado correctamente",
        [
          "Ya puedes acceder con tu usuario",
          "Ya puedes acceder con tu contraseña",
        ],
      ],
      actions: {
        ok: "Iniciar sesión",
      },
    },
  },
};

export default SessionSignup;
