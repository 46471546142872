import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Dropdown,
  Icon,
  Input,
} from "semantic-ui-react";
import { Texts } from "../data/Texts";
import _ from "lodash/fp";
import { useChannelscitiesFilters } from "../data/api/useChannels";

const ChannelsFilter = ({ filterChannels }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({ type: "girl" });
  const { cities } = useChannelscitiesFilters();
  const types = [
    { text: "Chica", value: "girl" },
    { text: "Chico", value: "boy" },
    { text: "Gay", value: "gay" },
    { text: "Trans", value: "trans" },
  ];

  const citiesFilters = _.flow(
    _.map(({ id, municipio, provincia }) => ({
      key: id,
      text: `${municipio} (${provincia})`,
      value: id,
    }))
  )(cities);

  const filter = (filter, value) => {
    const newFilters = { ...filters, [filter]: value };
    setFilters(newFilters);
    filterChannels(newFilters);
  };

  return (
    <div className="channels-filter">
      <div className="channels-filter-toggler">
        <div className="channels-filter-toggler-container">
          <Button primary onClick={() => setShowFilters(!showFilters)}>
            <Icon name="filter" />{" "}
            {showFilters
              ? Texts.channelsFilters.filters.close
              : Texts.channelsFilters.filters.open}
          </Button>
        </div>
      </div>
      <div className={`channels-filter-container ${showFilters ? "show" : ""}`}>
        <div className="channels-filter-container-left">
          <ButtonGroup>
            {_.map(({ text, value }) => (
              <Button
                key={value}
                primary={value === filters.type}
                onClick={() => filter("type", value)}
              >
                {text}
              </Button>
            ))(types)}
          </ButtonGroup>
          <Dropdown
            className="channels-filter-container-left-cities"
            text={Texts.channelsFilters.cities.all}
            selection
            multiple
            search
            clearable
            options={citiesFilters}
            onChange={(_, { value }) => filter("cities", value)}
          />
        </div>
        <div className="channels-filter-container-right">
          <div className="channels-filter-container-right-booleans">
            <Checkbox
              className="channels-filter-container-right-booleans-premium"
              toggle
              label={Texts.channelsFilters.premium}
              onChange={(_, { checked }) => filter("premium", checked)}
            />
            <Checkbox
              className="channels-filter-container-right-booleans-verified"
              toggle
              label={Texts.channelsFilters.verified}
              onChange={(_, { checked }) => filter("verified", checked)}
            />
          </div>
          <Input
            className="channels-filter-container-right-search"
            icon="search"
            placeholder={Texts.channelsFilters.search}
            onChange={(_, { value }) => filter("cities", value)}
          />
        </div>
      </div>
    </div>
  );
};

ChannelsFilter.propTypes = {
  filterChannels: PropTypes.func,
};

export default ChannelsFilter;
