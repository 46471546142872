import _ from "lodash/fp";
import React from "react";
import PropTypes from "prop-types";
import { Rating } from "semantic-ui-react";
import { useRatingCreate } from "../data/api/useRating";

const RatingStars = ({ rating, resource, by }) => {
  const { trigger } = useRatingCreate();
  const doRate = (rating) => {
    trigger({ rating, resource, by });
  };

  return (
    <Rating
      disabled={_.isEmpty(by.id)}
      icon="heart"
      defaultRating={1}
      rating={rating}
      maxRating={5}
      onRate={(_e, { rating }) => doRate(rating)}
    />
  );
};

RatingStars.propTypes = {
  rating: PropTypes.number,
  resource: PropTypes.object,
  by: PropTypes.object,
};

export default RatingStars;
