import React from "react";
import { Modal } from "semantic-ui-react";
import { SessionLoginContent } from "../components/SessionLoginContent";

export const SessionLoginModal = ({
  open,
  setOpenSessionLogin,
  updateUserData,
}) => {
  return (
    <Modal open={open} onClose={() => setOpenSessionLogin(false)} closeIcon>
      <SessionLoginContent
        updateUserData={updateUserData}
        setOpenSessionLogin={setOpenSessionLogin}
      />
    </Modal>
  );
};

export default SessionLoginModal;
