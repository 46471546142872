export const Credits = {
  hero: {
    preTitle: "Eleva tu placer",
    title: "Créditos",
    subTitle: "Accede a contenido exclusivo <br /> o potencia tus canales",
  },
  options: {
    credits: "Créditos",
    buy: "Comprar",
    save: "Ahorra",
    processing: "Procesando",
  },
  result: {
    ok: {
      copy: "Tu compra de créditos se ha completado",
      check: "Puedes comprobar tu saldo en",
      account: "tu área personal",
      ctaCopy: {
        user: "O suscríbete a algún canal para empezar a ver contenido exclusivo.",
        manager: "O publica un nuevo anuncio.",
      },
      cta: {
        user: "Ver canales",
        manager: "Nuevo anuncio",
      },
    },
    error: {
      copy: "Ha ocurrido un error con la transacción.",
      ctaCopy: "Por favor, vuelve a intentarlo.",
      cta: "Volver a créditos",
      bank: "Si el error sigue produciéndose, consulta con tu entidad bancaria.",
    },
  },
};

export default Credits;
