export const NavigationAccountUser = ["profile", "subscriptions", "wallet"];
export const NavigationAccountManager = ["profile", "channels", "wallet"];
export const NavigationAccountChannelNew = [
  "info",
  // "thumbnails",
];
export const NavigationAccountChannel = [
  "info",
  // "thumbnails",
  "audio",
  "gallery",
];
