import React, { useContext } from "react";
import parse from "html-react-parser";
import { PathRoutes } from "../../data/PathRoutes";
import { Imgs, Texts } from "../../data/Data";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { sessionContext } from "../../App";

const CreditsResult = ({ error }) => {
  const { type } = useContext(sessionContext) || { type: null };
  const isAllowed = () =>
    !type ? (window.location.href = PathRoutes.PATH_HOME) : false;
  isAllowed();
  const isManager = type === "manager";
  const accountLink = isManager
    ? PathRoutes.PATH_ACCOUNT_MANAGER
    : PathRoutes.PATH_ACCOUNT_USER;
  const ctaLink = isManager
    ? PathRoutes.PATH_ACCOUNT_CHANNEL_NEW
    : PathRoutes.PATH_CHANNELS;

  return (
    <main className="credits">
      <div
        className="credits-hero"
        style={{ backgroundImage: `url(${Imgs.credits.hero})` }}
      >
        <div className="credits-hero-content">
          <h5>{Texts.credits.hero.preTitle}</h5>
          <h1>{Texts.credits.hero.title}</h1>
          <h2>{parse(Texts.credits.hero.subTitle)}</h2>
        </div>
      </div>

      {error ? (
        <div className="credits-result">
          <p>{Texts.credits.result.error.copy}</p>
          <p>{Texts.credits.result.error.ctaCopy}</p>
          <Button primary as={Link} to={PathRoutes.PATH_CREDITS}>
            {Texts.credits.result.error.cta}
          </Button>
          <p>{Texts.credits.result.error.bank}</p>
        </div>
      ) : (
        <div className="credits-result">
          <p>{Texts.credits.result.ok.copy}</p>
          <p>
            {Texts.credits.result.ok.check}{" "}
            <Link to={accountLink}>{Texts.credits.result.ok.account}</Link>.
          </p>
          <p>
            {isManager
              ? Texts.credits.result.ok.ctaCopy.manager
              : Texts.credits.result.ok.ctaCopy.user}
          </p>
          <Button primary as={Link} to={ctaLink}>
            {isManager
              ? Texts.credits.result.ok.cta.manager
              : Texts.credits.result.ok.cta.user}
          </Button>
        </div>
      )}
    </main>
  );
};

export default CreditsResult;
