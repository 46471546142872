export const AccountManager = {
  title: "Mi Perfil",
  info: {
    title: "Mis Datos",
    form: {
      avatar: {
        label: "Foto de perfil",
        add: "Añadir",
        edit: "Editar",
        error: "Error en el formato de imagen. Ha de ser JPG, JPEG o PNG",
      },
      alias: { label: "Alias" },
      name: { label: "Nombre" },
      surname: { label: "Apellidos" },
      email: { label: "Email" },
      phone: { label: "Teléfono" },
      phone2: { label: "Teléfono 2" },
      whatsapp: { label: "Whatsapp" },
      "new-password": {
        label:
          "Nueva contraseña (rellenar solo en caso de querer cambiar la contraseña)",
      },
      postal: { label: "Dirección" },
      submit: { label: "Guardar" },
    },
  },
  channels: {
    title: "Mis Canales",
    new: "Nuevo canal",
    tableHeaders: {
      channel: "Canal",
      premium: "Premium",
      since: "Abierto desde",
      last_update: "Última Actualización",
      favs: "favoritos",
      subs: "Suscriptores",
    },
    tableBody: {
      edit: "Editar canal",
    },
  },
  messages: {
    success: {
      header: "Datos actualizados",
      content: ["Tus datos se han actualizado correctamente"],
      actions: {
        ok: "Aceptar",
      },
    },
  },
};

export default AccountManager;
