export const Channel = {
  seemore: "Ver más",
  actions: {
    follow: "Seguir",
    notifications: "Recibir notificaciones",
    comments: "Comentarios",
    report: "Denunciar canal",
  },
  content: {
    info: {
      labels: {
        location: "Ubicación",
        phone: "Teléfono",
        whatsapp: "Whatsapp",
        payments: "Formas de pago",
        type: "Categoría",
        tags: "Etiquetas",
        interests: "Intereses",
        description: "Descripción",
        map: "Mapa",
      },
      type: {
        girl: "Chica",
        boy: "Chico",
        gay: "Gay",
        trans: "Trans",
        onlyfans: "OnlyFans",
        rooms: "Habitaciones",
      },
      tags: {
        girl: "Chica",
        boy: "Chico",
        black: "Morena",
        blonde: "Rubia",
        redhair: "Peliroja",
        tall: "Alta",
        short: "Baja",
        english: "Ingles",
        french: "Frances",
        spanish: "Español",
        international: "Internacional",
      },
      interests: {
        meditation: "Meditación",
        fitness: "Fitness",
        beauty: "Belleza",
        cooking: "Cocinar",
        desserts_and_cakes: "Postres y pasteles",
        smoothies_and_juices: "Batidos y zumos",
        household_help: "Ayuda en casa",
        my_chores: "Mis labores",
        decoration: "Decoración",
        social_media: "Redes sociales",
        online_shopping: "Compras online",
        online_sales: "Ventas online",
        computer_science: "Informática",
        crafts: "Manualidades",
        other_cultures: "Otras culturas",
        games: "Juegos",
        video_games: "Videojuegos",
        shows_and_movies: "Series y pelis",
        spontaneous_outings: "Salidas improvisadas",
        party: "Salir de fiesta",
        shows: "Espectáculos",
        dancing: "Bailar",
        outdoor_activities: "Aire libre",
        museums: "Museos",
        indoor_sports: "Deportes indoor",
        fashion: "Moda",
        automotive: "Motor",
        nature: "Naturaleza",
        foreign_travel: "Viajes al extranjero",
        technology: "Tecnología",
        beach_trip: "Ir a la playa",
        group_outing: "Salir en grupo",
        wiki_wiki_meow_meow: "Wiki-wiki Miau-miau",
        massages: "Masajes",
      },
    },
  },
  tabs: {
    pictures: "Fotos",
    videos: "Vídeos",
    map: "Mapa",
  },
  messages: {
    report: {
      header: "Reportar Canal",
      content: [
        `Para reportar este canal, escribe un correo a info@vivalavidaloca.com, indicando el canal ##__channel_name__## y el motivo del reporte`,
      ],
      actions: {
        ok: "Aceptar",
      },
    },
  },
};

export default Channel;
