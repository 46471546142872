import _ from "lodash/fp";
import React from "react";
import PropTypes from "prop-types";
import { Button, Segment, Table, TableCell } from "semantic-ui-react";
import { Texts } from "../data/Texts";
import { Link } from "react-router-dom";
import { PathRoutes } from "../data/PathRoutes";
import { useWallet } from "../data/api/useCredits";

const AccountWallet = ({ alias }) => {
  const { data } = useWallet(alias);

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    return date.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  return (
    <Segment className="account-user-wallet">
      <h3>{Texts.account.wallet.title}</h3>
      <Button primary as={Link} to={PathRoutes.PATH_CREDITS}>
        {Texts.account.wallet.buy}
      </Button>
      <Table>
        <Table.Header>
          <Table.Row>
            <TableCell>{Texts.account.wallet.tableHeaders.date}</TableCell>
            <TableCell>{Texts.account.wallet.tableHeaders.for}</TableCell>
            <TableCell>{Texts.account.wallet.tableHeaders.used}</TableCell>
            <TableCell>{Texts.account.wallet.tableHeaders.total}</TableCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(({ id, date, description, amount, balance }) => {
            return (
              <Table.Row key={id}>
                <TableCell>{formatDate(date)}</TableCell>
                <TableCell>{description}</TableCell>
                <TableCell>{amount}</TableCell>
                <TableCell>{balance}</TableCell>
              </Table.Row>
            );
          })(_.reverse(data))}
        </Table.Body>
      </Table>
    </Segment>
  );
};

AccountWallet.propTypes = { alias: PropTypes.string };

export default AccountWallet;
