import React, { useContext, useState } from "react";
// import PropTypes from "prop-types";
import { Button, Popup, Icon } from "semantic-ui-react";
import _ from "lodash/fp";
import { PathRoutes, Texts } from "../../data/Data";
import { sessionContext } from "../../App";
import { Link, useParams } from "react-router-dom";
import { useUserAction } from "../../data/api/useUser";
import { useVideo } from "../../data/api/useVideo";
import { useCommentCreate } from "../../data/api/useComment";
import Comments from "../../components/Comments";
import { useRating } from "../../data/api/useRating";
import RatingStars from "../../components/RatingStar";
import { channelImgs } from "../../data/Imgs";

const ChannelVideo = (props) => {
  const userData = useContext(sessionContext);
  const { channel_id, video_id } = useParams();
  const { data, isLoading, mutate } = useVideo(video_id);
  const { trigger: triggerCommentCreate } = useCommentCreate();
  const { data: ratingData } = useRating("video", video_id);
  const [commentMessage, setCommentMessage] = useState({
    msg: "",
    count: 0,
    warning: 50,
    max: 450,
    status: null,
    channelId: null,
  });
  const { trigger: triggerAction } = useUserAction();
  const alias = userData?.alias || null;

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    return date.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const handleCommentMessage = (message) => {
    const messageLength = message.length;
    const messageRemain = commentMessage.max - messageLength;
    const messageStatus = _.cond([
      [(r) => r <= 0, () => "danger"],
      [(r) => r <= commentMessage.warning, () => "warning"],
      [_.stubTrue, () => null],
    ])(messageRemain);
    if (messageRemain >= 0) {
      setCommentMessage({
        ...commentMessage,
        msg: message,
        count: messageLength,
        status: messageStatus,
      });
    }
  };

  const handleCommentChannel = (channelId) =>
    setCommentMessage({ ...commentMessage, channelId });

  const handleCommentCreate = () => {
    // refactor when default channel for managers, passing just userData
    const messageUser =
      userData?.type === "manager"
        ? {
            type: "channel",
            id: commentMessage.channelId,
          }
        : {
            type: "user",
            id: parseInt(userData?.id),
          };

    const payload = {
      resource: {
        type: "video",
        id: data.id,
      },
      by: {
        type: messageUser.type,
        id: messageUser.id,
      },
      message: commentMessage.msg,
    };

    triggerCommentCreate(payload).then((response) => {
      mutate();
      handleCommentMessage("");
    });
  };

  const userAction = (action, status) =>
    triggerAction({ user: alias, action, status, channel: channel_id });

  return isLoading ? null : (
    <main className="channel-video">
      <div className="channel-video-content">
        <h1 className="channel-video-content-title">{data.title}</h1>
        <div className="channel-video-content-video">
          <video width="100%" height="100%" controls>
            <source src={data.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="channel-video-content-metadata">
          <div className="channel-video-content-metadata-channel">
            <a
              href={`/${data.channel.slug}`}
              className="channel-video-content-metadata-channel-info"
            >
              <img
                className="channel-video-content-metadata-channel-info-avatar"
                src={data.channel.thumbnail}
                alt=""
              />
              <h2 className="channel-video-content-metadata-channel-info-name">
                {data.channel.display_name}
              </h2>
            </a>
            <div className="channel-video-content-metadata-channel-actions">
              <RatingStars
                rating={ratingData?.rating}
                resource={{ type: "video", id: parseInt(data.id) }}
                by={{ type: "user", id: alias }}
              />
              <Popup
                content={Texts.channelVideo.content.actions.follow}
                trigger={
                  <Button
                    disabled={!alias}
                    color="red"
                    onClick={() => userAction("follow", true)}
                  >
                    <Icon name="heart" />
                    {data.channel.follows}
                  </Button>
                }
              />
              <Popup
                content={Texts.channelVideo.content.actions.comments}
                trigger={
                  <Button secondary icon="comments" onClick={() => null} />
                }
              />
            </div>
          </div>
          <div className="channel-video-content-metadata-info">
            <p className="channel-video-content-metadata-info-date">
              {Texts.channelVideo.content.info.uploadDate}{" "}
              {formatDate(data.inserted_at)}
            </p>
            <p>{data.description}</p>
          </div>
          <Comments
            comments={data.comments}
            commentMessage={{
              ...commentMessage,
              handleCommentChannel: handleCommentChannel,
              handleCommentMessage: handleCommentMessage,
              handleCommentCreate: handleCommentCreate,
            }}
            userData={userData}
          />
        </div>
      </div>
      <div className="channel-video-sidebar">
        <h2 className="channel-video-sidebar-title">
          {Texts.channelVideo.relatedVideos.title}
        </h2>
        <ul className="channel-video-sidebar-list">
          {_.map(({ id, code, thumbnail, channel }) => {
            const thumb = _.isEmpty(thumbnail)
              ? channelImgs.videoMissingThumb
              : `//${thumbnail.url}`;

            return (
              <li className="channel-video-sidebar-list-item" key={id}>
                <Link
                  to={PathRoutes.PATH_CHANNEL_VIDEO.replace(
                    ":channel_id",
                    channel.slug
                  ).replace(":video_id", code)}
                  target="_blank"
                >
                  <img
                    alt=""
                    className="channel-content-gallery-picture"
                    src={thumb}
                  />
                </Link>
                <a
                  href={`/${channel.slug}`}
                  className="channel-video-sidebar-list-item-channel"
                >
                  <img
                    className="channel-video-sidebar-list-item-channel-avatar"
                    src={channel.thumbnail}
                    alt={channel.display_name}
                  />
                  <h2 className="channel-video-sidebar-list-item-channel-name">
                    {channel.display_name}
                  </h2>
                </a>
              </li>
            );
          })(data.related)}
        </ul>
      </div>
    </main>
  );
};

ChannelVideo.propTypes = {};

export default ChannelVideo;
