import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Form, FormField, Input } from "semantic-ui-react";
import { Texts } from "../data/Texts";
import { useAudioUpload } from "../data/api/useMedia";

const AccountChannelAudio = ({ channel_id, audio_clip, mutate }) => {
  const [audioFile, setAudioFile] = useState({});
  const { trigger } = useAudioUpload(audio_clip?.id);

  useEffect(() => {
    return audio_clip?.url
      ? setAudioFile((prevAudioFile) => ({
          ...prevAudioFile,
          url: `//${audio_clip?.url}`,
        }))
      : setAudioFile((prevAudioFile) => ({ ...prevAudioFile }));
  }, [audio_clip]);

  const handleAudioFileChange = (e, _data) => {
    if (!e.target.files[0]) {
      return;
    }

    setAudioFile({
      ...audioFile,
      file: e.target.files[0],
      type: e.target.files[0].type,
      url: URL.createObjectURL(e.target.files[0]),
    });
  };

  const uploadAudio = () => {
    const formData = new FormData();
    formData.append("channel_id", channel_id);
    formData.append("audio", audioFile.file);

    trigger(formData).then(() => {
      mutate();
    });
  };

  return (
    <div className="account-channel-gallery">
      <h2>{Texts.account.channel.gallery.audio.title}</h2>

      <Form className="account-channel-gallery-audio">
        {audioFile.url ? (
          <audio width="100%" height="100%" controls key={audioFile.url}>
            <source src={audioFile.url} />
          </audio>
        ) : null}
        <FormField className="account-channel-gallery-audio-field">
          <label>{Texts.account.channel.gallery.audio.fileLabel}</label>
          <Input
            type="file"
            name="audio"
            onChange={handleAudioFileChange}
            accept="audio/*"
          />
        </FormField>
        <FormField className="account-channel-gallery-audio-button">
          <Button
            disabled={!audioFile.file}
            content={Texts.account.channel.gallery.audio.button}
            primary
            onClick={() => uploadAudio()}
          />
        </FormField>
      </Form>
    </div>
  );
};

AccountChannelAudio.propTypes = {
  channel_id: PropTypes.number,
  audio_clip: PropTypes.object,
  mutate: PropTypes.func,
};

export default AccountChannelAudio;
