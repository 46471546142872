const AccountChannelForm = [
  { name: "id", type: "hidden" },
  { name: "channel", type: "text", is_required: true },
  { name: "display_name", type: "text", is_required: true },
  {
    name: "type",
    type: "select",
    options: ["girl", "boy", "gay", "trans", "onlyfans", "rooms"],
    translatedOptions: {
      girl: "Chica",
      boy: "Chico",
      gay: "Gay",
      trans: "Trans",
      onlyfans: "OnlyFans",
      rooms: "Habitaciones",
    },
  },
  {
    name: "tags",
    type: "select",
    multiple: true,
    searchable: true,
    options: [
      "girl",
      "boy",
      "black",
      "blonde",
      "redhair",
      "tall",
      "short",
      "english",
      "french",
      "spanish",
      "international",
    ],
  },
  { name: "social_instagram", type: "text" },
  { name: "social_x", type: "text" },
  { name: "social_onlyfans", type: "text" },
  { name: "social_other", type: "text" },
  { name: "short_description", type: "textarea", is_required: true },
  { name: "description", type: "textarea", is_required: true },
  { name: "phone", type: "tel" },
  { name: "whatsapp", type: "tel" },
  { name: "credit_card", type: "checkbox" },
  { name: "other_payments", type: "checkbox" },
  {
    name: "country",
    type: "text",
    default_value: "España",
    is_disabled: true,
  },
  {
    name: "province",
    type: "province",
    multiple: true,
    searchable: true,
    options: [],
  },
  {
    name: "city",
    type: "city",
    multiple: true,
    searchable: true,
    options: [],
  },
  { name: "zone", type: "text" },
  { name: "languajes", type: "text" },
  { name: "map", type: "textarea" },
  {
    name: "interests",
    type: "select",
    multiple: true,
    searchable: true,
    options: [
      "meditation",
      "fitness",
      "beauty",
      "cooking",
      "desserts_and_cakes",
      "smoothies_and_juices",
      "household_help",
      "my_chores",
      "decoration",
      "social_media",
      "online_shopping",
      "online_sales",
      "computer_science",
      "crafts",
      "other_cultures",
      "games",
      "video_games",
      "shows_and_movies",
      "spontaneous_outings",
      "party",
      "shows",
      "dancing",
      "outdoor_activities",
      "museums",
      "indoor_sports",
      "fashion",
      "automotive",
      "nature",
      "foreign_travel",
      "technology",
      "beach_trip",
      "group_outing",
      "wiki_wiki_meow_meow",
      "massages",
    ],
  },
  { name: "channelThumbnail", type: "picture", ratio: "vertical" },
  { name: "channelTopVertical", type: "picture", ratio: "vertical" },
  { name: "channelTopHorizontal", type: "picture", ratio: "horizontal" },
  { name: "submit", type: "submit" },
];

export default AccountChannelForm;
