import _ from "lodash/fp";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useChannel } from "../../data/api/useChannel";
import { PathRoutes, Texts } from "../../data/Data";
import { Button, Icon, Modal, Popup, Tab, TabPane } from "semantic-ui-react";
import { useUserAction } from "../../data/api/useUser";
import { sessionContext } from "../../App";
import { API_URL } from "../../data/api/endpoints";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import MessageModal from "../../components/MessageModal";
import { channelImgs } from "../../data/Imgs";
import SubscribeButton from "../../components/SubscribeButton";
import Comments from "../../components/Comments";
import { useCommentCreate } from "../../data/api/useComment";
import { useRating } from "../../data/api/useRating";
import RatingStars from "../../components/RatingStar";

const Channel = (props) => {
  const userData = useContext(sessionContext);
  const [reportOpen, setReportOpen] = useState(false);
  const { id } = useParams();
  const { channel, mutate } = useChannel(id, true);
  const { trigger: triggerCommentCreate } = useCommentCreate();
  const { trigger: triggerAction } = useUserAction();
  const { data: ratingData } = useRating("channel", id);
  const [open, setOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [commentMessage, setCommentMessage] = useState({
    msg: "",
    count: 0,
    warning: 50,
    max: 450,
    status: null,
    channelId: null,
  });
  const [audioClipPlayer, setAudioClipPlayer] = useState(null);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);

  useEffect(() => {
    if (channel.audio_clip) {
      const player = new Audio(`//${channel.audio_clip.url}`);
      player.addEventListener("ended", () => {
        player.currentTime = 0;
        setIsPlayingAudio(false);
      });
      setAudioClipPlayer(player);
    }
  }, [channel.audio_clip]);

  const alias = userData?.alias || null;

  const parsedChannel = _.flow(
    ({
      pictures,
      channelThumbnail,
      channelTopVertical,
      channelTopHorizontal,
      social_network,
      interests,
      credit_card,
      other_payments,
      vids,
    }) => {
      const parsedPictures = _.flow(
        _.split(";"),
        _.map((value) => _.trim(value)),
        _.filter((value) => !_.isEmpty(value)),
        _.map((value) => API_URL + value)
      )(pictures);

      const parsedFeedPictures = _.flow(
        _.mapValues((value) => _.trim(value)),
        _.pickBy((value) => !_.isEmpty(value)),
        _.mapValues((value) =>
          _.startsWith("http")(value) ? value : API_URL + value
        )
      )({ channelThumbnail, channelTopVertical, channelTopHorizontal });

      const parsedSocial = _.flow(
        _.split(";"),
        _.map((value) => _.trim(value)),
        _.reduce(
          (acc, value) => {
            const key = _.flow(
              _.cond([
                [_.includes("instagram"), _.constant("social_instagram")],
                [_.includes("x"), _.constant("social_x")],
                [_.includes("twitter"), _.constant("social_x")],
                [_.includes("onlyfans"), _.constant("social_onlyfans")],
                [_.stubTrue, _.constant("social_other")],
              ])
            )(value);
            return { ...acc, [key]: value };
          },
          {
            social_x: "",
            social_instagram: "",
            social_onlyfans: "",
            social_other: "",
          }
        )
      )(social_network);

      const parsedInterests = _.flow(
        _.split(";"),
        _.map((value) => _.trim(value)),
        _.filter((value) => !_.isEmpty(value))
      )(interests);

      const parsedCreditCard = _.toInteger(credit_card);
      const parsedOtherPayments = _.toInteger(other_payments);

      const parsedVideos = _.flow(
        _.reject((v) => _.includes(v)([".", ".."])),
        _.reject((v) => _.startsWith("path:")(v)),
        _.reject((v) => _.endsWith(".mp3")(v))
      )(vids);

      return {
        ...channel,
        ...parsedFeedPictures,
        pictures: parsedPictures,
        videos: parsedVideos,
        ...parsedSocial,
        interests: parsedInterests,
        payments: {
          credit_card: parsedCreditCard,
          other_payments: parsedOtherPayments,
        },
      };
    }
  )(channel);

  const [allPictures, allVideos] = _.flow(
    _.get("media"),
    _.partition({ type: "image" })
  )(parsedChannel);

  const userAction = (action, status) =>
    triggerAction({ user: alias, action, status, channel: id });

  const mapQuery = encodeURI(parsedChannel.map);

  const reportMessage = () => {
    const contentReplace = _.map((content) =>
      _.replace("##__channel_name__##", channel.channel)(content)
    )(Texts.channel.messages.report.content);

    return { ...Texts.channel.messages.report, content: contentReplace };
  };

  const play_clip = () => {
    if (!isPlayingAudio) {
      audioClipPlayer.play();
      setIsPlayingAudio(true);
    } else {
      audioClipPlayer.pause();
      audioClipPlayer.currentTime = 0;
      setIsPlayingAudio(false);
    }
  };

  const handleCommentMessage = (message) => {
    const messageLength = message.length;
    const messageRemain = commentMessage.max - messageLength;
    const messageStatus = _.cond([
      [(r) => r <= 0, () => "danger"],
      [(r) => r <= commentMessage.warning, () => "warning"],
      [_.stubTrue, () => null],
    ])(messageRemain);
    if (messageRemain >= 0) {
      setCommentMessage({
        ...commentMessage,
        msg: message,
        count: messageLength,
        status: messageStatus,
      });
    }
  };

  const handleCommentChannel = (channelId) =>
    setCommentMessage({ ...commentMessage, channelId });

  const handleCommentCreate = () => {
    // refactor when default channel for managers, passing just userData
    const messageUser =
      userData?.type === "manager"
        ? {
            type: "channel",
            id: commentMessage.channelId,
          }
        : {
            type: "user",
            id: parseInt(userData?.id),
          };

    const payload = {
      resource: {
        type: "channel",
        id: channel.id,
      },
      by: {
        type: messageUser.type,
        id: messageUser.id,
      },
      message: commentMessage.msg,
    };

    triggerCommentCreate(payload).then((response) => {
      mutate();
      handleCommentMessage("");
    });
  };

  const panes = [
    {
      menuItem: Texts.channel.tabs.pictures,
      render: () => (
        <TabPane>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 1300: 3 }}
          >
            <Masonry gutter="5px">
              {_.map(({ url }) => {
                return (
                  <img
                    alt=""
                    className="channel-content-gallery-picture"
                    src={`//${url}`}
                    onClick={() => setOpen(true)}
                  />
                );
              })(allPictures)}
            </Masonry>
          </ResponsiveMasonry>
        </TabPane>
      ),
    },
    {
      menuItem: Texts.channel.tabs.videos,
      render: () => (
        <TabPane>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 1300: 3 }}
          >
            <Masonry gutter="5px">
              {_.map((video) => {
                return (
                  <video width="320" height="240" controls>
                    <source
                      src={`${API_URL}/vlvl/api/media/channels/${channel.channel}/vids/${video}`}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                );
              })(parsedChannel.videos)}
              {_.map(({ code, thumbnail }) => {
                const thumb = _.isEmpty(thumbnail)
                  ? channelImgs.videoMissingThumb
                  : `//${thumbnail.url}`;

                return (
                  <Link
                    to={PathRoutes.PATH_CHANNEL_VIDEO.replace(
                      ":channel_id",
                      id
                    ).replace(":video_id", code)}
                    target="_blank"
                  >
                    <img
                      alt=""
                      className="channel-content-gallery-picture"
                      src={thumb}
                    />
                  </Link>
                );
              })(allVideos)}
            </Masonry>
          </ResponsiveMasonry>
        </TabPane>
      ),
    },
    {
      menuItem: Texts.channel.tabs.map,
      render: () => (
        <TabPane>
          <label>
            <Icon name="map marker" size="small" />
            {Texts.channel.content.info.labels.map}
          </label>
          <iframe
            title="Channel Map"
            width="100%"
            height="300"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src={`https://maps.google.com/maps?width=100%25&height=600&hl=es&q=${mapQuery}&t=&z=14&ie=UTF8&iwloc=B&output=embed`}
          ></iframe>
        </TabPane>
      ),
    },
  ];

  return _.isEmpty(channel) ? (
    <main className="channel">
      <div className="channel-content">Missing channel</div>
    </main>
  ) : (
    <main className="channel">
      <div className="channel-content">
        <div className="channel-content-header">
          <img
            alt=""
            className="channel-content-header-avatar"
            src={parsedChannel.channelThumbnail}
          />
          <div className="channel-content-header-info">
            <h1 className="channel-content-header-info-title">{`${parsedChannel.display_name}, ${parsedChannel.short_description}`}</h1>
            <p onClick={() => setInfoOpen(true)}>
              {_.truncate({ length: 30 })(parsedChannel.description)}{" "}
              <Button size="mini" primary>
                {Texts.channel.seemore}
              </Button>
            </p>
          </div>
        </div>
        <div className="channel-content-city-actions">
          <p>
            <Icon name="point" />
            {parsedChannel.city}, {parsedChannel.zone}
          </p>
          <div className="channel-content-actions">
            <RatingStars
              rating={ratingData?.rating}
              resource={{ type: "channel", id: parseInt(parsedChannel.id) }}
              by={{ type: "user", id: userData?.alias }}
            />
            <SubscribeButton
              channel={{
                channel: parsedChannel.channel,
                name: parsedChannel.display_name,
                subs: parsedChannel.subs,
                thumbnail: parsedChannel.channelThumbnail,
              }}
              user={{ alias }}
            />
            <Popup
              content={Texts.channel.actions.follow}
              trigger={
                <Button
                  disabled={!alias}
                  color="red"
                  onClick={() => userAction("follow", true)}
                >
                  <Icon name="heart" />
                  {parsedChannel.follows || 0}
                </Button>
              }
            />
            <Popup
              content={Texts.channel.actions.notifications}
              trigger={
                <Button
                  disabled={!alias}
                  secondary
                  icon="bell"
                  onClick={() => userAction("notifications", true)}
                />
              }
            />
            <Popup
              content={Texts.channel.actions.comments}
              trigger={
                <Button
                  secondary
                  icon="comments"
                  onClick={() => {
                    const commentsElement = document.getElementById(
                      "channel-content-comments"
                    );
                    const targetPosition = commentsElement.offsetTop - 60;
                    window.scrollTo({
                      top: targetPosition,
                      behavior: "smooth",
                    });
                  }}
                />
              }
            />
            <Popup
              content={Texts.channel.actions.report}
              trigger={
                <Button
                  secondary
                  icon="exclamation"
                  onClick={() => setReportOpen(true)}
                />
              }
            />
          </div>
        </div>
        <div className="channel-content-gallery">
          <Tab
            className="channel-content-gallery-tabs"
            panes={panes}
            menu={{ attached: false }}
          />
        </div>
        <Comments
          id="channel-content-comments"
          comments={channel.comments}
          commentMessage={{
            ...commentMessage,
            handleCommentChannel: handleCommentChannel,
            handleCommentMessage: handleCommentMessage,
            handleCommentCreate: handleCommentCreate,
          }}
          userData={userData}
        />
      </div>
      <div className="channel-thumbnail">
        <img
          className="channel-thumbnail-img"
          alt=""
          src={parsedChannel.channelThumbnail}
        />
        <ul className="channel-thumbnail-social">
          {_.map((network) => {
            return parsedChannel["social_" + _.toLower(network)] ? (
              <li
                key={network}
                className={`channel-thumbnail-social-link ${_.toLower(
                  network
                )}`}
              >
                <Link
                  to={parsedChannel["social_" + _.toLower(network)]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {_.cond([
                    [
                      _.equals("instagram"),
                      (n) => (
                        <Icon
                          name={n}
                          size="big"
                          className="channel-thumbnail-social-link-icon"
                        />
                      ),
                    ],
                    [
                      _.equals("x"),
                      () => (
                        <img
                          alt=""
                          src={channelImgs.social[_.toLower(network)]}
                          className="channel-thumbnail-social-link-icon"
                        />
                      ),
                    ],
                    [
                      _.equals("onlyfans"),
                      () => (
                        <img
                          alt=""
                          src={channelImgs.social[_.toLower(network)]}
                          className="channel-thumbnail-social-link-icon"
                        />
                      ),
                    ],
                    [
                      _.equals("other"),
                      () => (
                        <Icon
                          name="world"
                          size="big"
                          className="channel-thumbnail-social-link-icon"
                        />
                      ),
                    ],
                  ])(_.toLower(network))}
                </Link>
              </li>
            ) : null;
          })(["Instagram", "X", "OnlyFans", "Other"])}
          <li>
            <a
              href={`https://wa.me/${parsedChannel.whatsapp}?text=${encodeURI(
                "Hola, te he conocido en VivaLaVidaLoca y quiero conocerte mejor."
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="whatsapp"
                size="big"
                className="channel-thumbnail-social-link-icon"
              />
            </a>
          </li>
          {audioClipPlayer ? (
            <li onClick={play_clip}>
              <Icon
                className="channel-thumbnail-social-link-icon audio-clip"
                name={isPlayingAudio ? "pause" : "music"}
                size="big"
              />
            </li>
          ) : null}
        </ul>
      </div>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={_.map(({ url }) => {
          return { src: `//${url}` };
        })(allPictures)}
        styles={{ container: { backgroundColor: "rgba(255, 255, 255, .8)" } }}
      />
      <MessageModal
        open={reportOpen}
        texts={reportMessage()}
        action={() => setReportOpen(false)}
        onClose={() => setReportOpen(false)}
      />
      <Modal
        closeIcon
        open={infoOpen}
        onClose={() => setInfoOpen(false)}
        onOpen={() => setInfoOpen(true)}
      >
        <div className="channel-content-info">
          <div className="channel-content-info-block">
            <label>
              <Icon name="target" size="small" />
              {Texts.channel.content.info.labels.location}
            </label>
            <p className="location">
              {parsedChannel.city}, {parsedChannel.zone},{" "}
              {parsedChannel.country}
            </p>
          </div>
          <div className="channel-content-info-block">
            <label>
              <Icon name="phone" size="small" />
              {Texts.channel.content.info.labels.phone}
            </label>
            <p className="phone">
              <a
                href={`tel:${parsedChannel.phone}}`}
                target="_blank"
                rel="noreferrer"
              >
                {parsedChannel.phone}
              </a>
            </p>
          </div>
          <div className="channel-content-info-block">
            <label>
              <Icon name="whatsapp" size="small" />
              {Texts.channel.content.info.labels.whatsapp}
            </label>
            <p className="whatsapp">
              <a
                href={`https://wa.me/${parsedChannel.whatsapp}?text=${encodeURI(
                  "Hola, te he conocido en VivaLaVidaLoca y quiero conocerte mejor."
                )}`}
                target="_blank"
                rel="noreferrer"
              >
                {parsedChannel.whatsapp}
              </a>
            </p>
          </div>
          <div className="channel-content-info-block">
            <label>
              <Icon name="credit card" size="small" />
              {Texts.channel.content.info.labels.payments}
            </label>
            <p className="payments">
              {parsedChannel.payments.credit_card ? "Tarjeta" : ""}
              {parsedChannel.payments.credit_card &&
              parsedChannel.payments.other_payments
                ? ", "
                : ""}
              {parsedChannel.payments.other_payments ? "Bizzum" : ""}
            </p>
          </div>
          <div className="channel-content-info-block">
            <label>
              <Icon name="intersex" size="small" />
              {Texts.channel.content.info.labels.type}
            </label>
            <p className="type">
              {Texts.channel.content.info.type[parsedChannel.type]}
            </p>
          </div>
          <div className="channel-content-info-block">
            <label>
              <Icon name="tags" size="small" />
              {Texts.channel.content.info.labels.tags}
            </label>
            <p className="tags">
              {_.flow(
                _.split(";"),
                _.map((tag) => Texts.channel.content.info.tags[tag]),
                _.join(", ")
              )(parsedChannel.tags)}
            </p>
          </div>
          <div className="channel-content-info-block">
            <label>
              <Icon name="thumbs up" size="small" />
              {Texts.channel.content.info.labels.interests}
            </label>
            <p className="interests">
              {_.flow(
                _.split(","),
                _.map(
                  (interest) => Texts.channel.content.info.interests[interest]
                ),
                _.join(", ")
              )(parsedChannel.interests)}
            </p>
          </div>
          <div className="channel-content-info-block description">
            <label>
              <Icon name="file alternate" size="small" />
              {Texts.channel.content.info.labels.description}
            </label>
            <p className="description">{parsedChannel.description}</p>
          </div>
        </div>
      </Modal>
    </main>
  );
};

Channel.propTypes = {};

export default Channel;
