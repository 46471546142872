import _ from "lodash/fp";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import {
  NavigationAccountUser,
  NavigationAccountManager,
  NavigationAccountChannelNew,
  NavigationAccountChannel,
} from "../data/NavigationAccount";
import { Texts } from "../data/Data";

const AccountSidebar = ({
  show,
  section,
  navigationSelected,
  setNavigationSelected,
}) => {
  const menu = _.cond([
    [_.isEqual("user"), () => NavigationAccountUser],
    [_.isEqual("manager"), () => NavigationAccountManager],
    [_.isEqual("channelNew"), () => NavigationAccountChannelNew],
    [_.isEqual("channel"), () => NavigationAccountChannel],
    [_.stubTrue, () => []],
  ])(section);

  useEffect(() => {
    setNavigationSelected(_.first(menu));
  }, []);

  return (
    <div className={`account-sidebar ${show ? "open" : ""}`}>
      <ul className="account-sidebar-list">
        {_.map((item) => (
          <li
            className={`account-sidebar-list-item ${
              _.isEqual(navigationSelected)(item) ? "selected" : null
            }`}
            key={item}
            onClick={() => setNavigationSelected(item)}
          >
            {Texts.account.wrapper.menu[item]}
          </li>
        ))(menu)}
      </ul>
    </div>
  );
};

AccountSidebar.propTypes = {
  show: PropTypes.bool,
};

const AccountSidebarMobileToggle = ({
  openAccountSidebar,
  handleOpenAccountSidebar,
}) => {
  return (
    <div className="account-sidebar-mobile-toggler">
      {openAccountSidebar ? (
        <Button
          secondary
          content={Texts.account.wrapper.button.close}
          onClick={handleOpenAccountSidebar}
        />
      ) : (
        <Button
          primary
          content={Texts.account.wrapper.button.open}
          onClick={handleOpenAccountSidebar}
        />
      )}
    </div>
  );
};

const AccountWrapper = ({ section, children }) => {
  const [openAccountSidebar, setOpenAccountSidebar] = useState(false);
  const [navigationSelected, setNavigationSelected] = useState();

  const handleOpenAccountSidebar = () => {
    setOpenAccountSidebar(!openAccountSidebar);
  };

  return (
    <div className="account-wrapper">
      <AccountSidebar
        key={section}
        show={openAccountSidebar}
        section={section}
        navigationSelected={navigationSelected}
        setNavigationSelected={setNavigationSelected}
      />
      {React.cloneElement(children, { navigationSelected: navigationSelected })}
      <AccountSidebarMobileToggle
        openAccountSidebar={openAccountSidebar}
        handleOpenAccountSidebar={handleOpenAccountSidebar}
      />
    </div>
  );
};

AccountWrapper.propTypes = {};

export default AccountWrapper;
