import Navigation from "./texts/Navigation";
import Home from "./texts/Home";
import ContentCreators from "./texts/ContentCreators";
import About from "./texts/About";
import Platform from "./texts/Platform";
import SessionLogin from "./texts/SessionLogin";
import SessionPasswordRecovery from "./texts/SessionPasswordRecovery";
import SessionSignup from "./texts/SessionSignup";
import SessionSignupManager from "./texts/SessionSignupManager";
import AccountWrapper from "./texts/AccountWrapper";
import AccountUser from "./texts/AccountUser";
import AccountManager from "./texts/AccountManager";
import AccountChannel from "./texts/AccountChannel";
import AccountWallet from "./texts/AccountWallet";
import Credits from "./texts/Credits";
import LegalLegalNotice from "./texts/LegalLegalNotice";
import LegalCancellationReturnsAndShippingPolicy from "./texts/LegalCancellationReturnsAndShippingPolicy";
import LegalContentPolicy from "./texts/LegalContentPolicy";
import LegalCookiePolicy from "./texts/LegalCookiePolicy";
import LegalPrivacyPolicy from "./texts/LegalPrivacyPolicy";
import LegalTermsOfService from "./texts/LegalTermsOfService";
import Cookies from "./texts/Cookies";
import Agegate from "./texts/Agegate";
import Channels from "./texts/Channels";
import ChannelsFilters from "./texts/ChannelsFilters";
import Channel from "./texts/Channel";
import ChannelVideo from "./texts/ChannelVideo";
import Comments from "./texts/Comments";
import SubscribeButton from "./texts/SuscribeButton";

export const Texts = {
  navigation: Navigation,
  home: Home,
  contentCreators: ContentCreators,
  about: About,
  channels: Channels,
  channelsFilters: ChannelsFilters,
  channel: Channel,
  channelVideo: ChannelVideo,
  comments: Comments,
  platform: Platform,
  account: {
    wrapper: AccountWrapper,
    user: AccountUser,
    manager: AccountManager,
    channel: AccountChannel,
    wallet: AccountWallet,
  },
  session: {
    login: SessionLogin,
    passwordRecovery: SessionPasswordRecovery,
    signup: { user: SessionSignup, manager: SessionSignupManager },
  },
  credits: Credits,
  legal: {
    legalNotice: LegalLegalNotice,
    cancellationReturnsAndShippingPolicy:
      LegalCancellationReturnsAndShippingPolicy,
    contentPolicy: LegalContentPolicy,
    cookiePolicy: LegalCookiePolicy,
    privacyPolicy: LegalPrivacyPolicy,
    termsOfService: LegalTermsOfService,
  },
  cookies: Cookies,
  agegate: Agegate,
  subscribeButton: SubscribeButton,
};
