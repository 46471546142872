export const Comments = {
  title: "Comentarios",
  new: {
    title: "Deja tu comentario",
    titleManager: "Selecciona un canal para dejar tu comentario",
    selectChannel: "Selecciona un canal",
    send: "Enviar",
  },
};

export default Comments;
