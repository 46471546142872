import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import {
  Button,
  Container,
  Message,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Segment,
} from "semantic-ui-react";
import { PathRoutes, Texts } from "../../data/Data";
import {
  useChannelNew,
  useChannelInmutable,
  useChannelUpdate,
} from "../../data/api/useChannel";
import AccountForm from "../../components/AccountForm";
import AccountChannelForm from "../../data/forms/AccountChannelForm";
import AccountChannelAudio from "../../components/AccountChannelAudio";
import AccountChannelGallery from "../../components/AccountChannelGallery";
import _ from "lodash/fp";
import { API_URL } from "../../data/api/endpoints";
import MessageModal from "../../components/MessageModal";
import { sessionContext } from "../../App";
import { useProvincesCities } from "../../data/api/useProvincesCities";
import { useUserBalance } from "../../data/api/useCredits";

const AccountChannelPublishModal = ({
  showPublishModal,
  canPublish,
  balance,
  cities,
  setShowPublishModal,
  doSubmit,
}) => {
  const modalTexts = canPublish
    ? Texts.account.channel.publishModal.canPublish
    : Texts.account.channel.publishModal.cannotPublish;

  return (
    <Modal open={showPublishModal} onClose={() => setShowPublishModal(false)}>
      <ModalHeader>{modalTexts.title}</ModalHeader>
      <ModalContent>
        <p>{Texts.account.channel.publishModal.summary}:</p>
        <p>
          {Texts.account.channel.publishModal.balance}: {balance || 0}
        </p>
        <p>
          {Texts.account.channel.publishModal.cities}: {cities.length}
        </p>
        <ul>
          {_.map(({ municipio }) => <li key={municipio}>{municipio}</li>)(
            cities
          )}
        </ul>
      </ModalContent>
      <ModalActions>
        <Button
          secondary
          content={modalTexts.actions.cancel}
          onClick={() => setShowPublishModal(false)}
        />
        {canPublish ? (
          <Button
            primary
            content={modalTexts.actions.publish}
            onClick={() => doSubmit()}
          />
        ) : null}
      </ModalActions>
    </Modal>
  );
};

AccountChannelPublishModal.prototype = {};

const AccountChannel = ({
  channel = {},
  navigationSelected,
  trigger,
  mutate,
}) => {
  const {
    id: id_anunciante,
    alias,
    type: userType,
  } = useContext(sessionContext);
  const [open, setOpen] = useState(false);
  const [openMedia, setOpenMedia] = useState(false);
  const [channelFormValues, setChannelFormValues] = useState(null);
  const [slug, setSlug] = useState(channel.channel);
  const [canPublish, setCanPublish] = useState(false);
  const [balance, setBalance] = useState();
  const [showPublishModal, setShowPublishModal] = useState(false);
  const { data: provincesCities } = useProvincesCities();
  const { trigger: triggerUserBalance } = useUserBalance(alias);

  const isAllowed = () => {
    return userType === "user"
      ? (window.location.href = PathRoutes.PATH_ACCOUNT_USER)
      : null;
  };
  isAllowed();

  const handleOnSubmit = (values) => {
    setChannelFormValues(values);
    setSlug(values.channel);
    triggerUserBalance().then((response) => {
      setBalance(response);
      if (values?.city?.length) {
        setCanPublish(
          response >= values.city.length,
          setShowPublishModal(true)
        );
      } else {
        alert("Select at least one city");
      }
    });
    return;
  };

  const doSubmit = () =>
    trigger({ id_anunciante, ...channelFormValues }).then((response) => {
      if (response?.error) {
        setShowPublishModal(false, handleOnSubmit(channelFormValues));
      } else {
        setShowPublishModal(false, setOpen(true));
      }
    });

  const parsedChannel = _.flow(
    ({
      pictures,
      channelThumbnail,
      channelTopVertical,
      channelTopHorizontal,
      social_network,
      interests,
      vids,
    }) => {
      const parsedPictures = _.flow(
        _.split(";"),
        _.map((value) => _.trim(value)),
        _.filter((value) => !_.isEmpty(value)),
        _.map((value) => API_URL + value)
      )(pictures);

      const parsedFeedPictures = _.flow(
        _.mapValues((value) => _.trim(value)),
        _.pickBy((value) => !_.isEmpty(value)),
        _.mapValues((value) => API_URL + value)
      )({ channelThumbnail, channelTopVertical, channelTopHorizontal });

      const parsedSocial = _.flow(
        _.split(";"),
        _.map((value) => _.trim(value)),
        _.reduce(
          (acc, value) => {
            const key = _.flow(
              _.cond([
                [_.includes("instagram"), _.constant("social_instagram")],
                [_.includes("x"), _.constant("social_x")],
                [_.includes("twitter"), _.constant("social_x")],
                [_.includes("onlyfans"), _.constant("social_onlyfans")],
                [_.stubTrue, _.constant("social_other")],
              ])
            )(value);
            return { ...acc, [key]: value };
          },
          {
            social_x: "",
            social_instagram: "",
            social_onlyfans: "",
            social_other: "",
          }
        )
      )(social_network);

      const parsedInterests = _.flow(
        _.split(";"),
        _.map((value) => _.trim(value)),
        _.filter((value) => !_.isEmpty(value))
      )(interests);

      const parsedVideos = _.flow(
        _.reject((v) => _.includes(v)([".", ".."])),
        _.reject((v) => _.startsWith("path:")(v)),
        _.map(
          (v) =>
            `${API_URL}/vlvl/api/media/channels/${channel.channel}/vids/${v}`
        )
      )(vids);

      return {
        ...channel,
        ...parsedFeedPictures,
        pictures: parsedPictures,
        ...parsedSocial,
        interests: parsedInterests,
        videos: parsedVideos,
      };
    }
  )(channel);

  const getCities = (cities) =>
    _.flow(
      _.get("municipios"),
      _.filter(({ id }) =>
        _.includes(parseInt(id))(_.map((city) => parseInt(city))(cities))
      )
    )(provincesCities);

  console.log("navigationSelected", navigationSelected);

  return userType !== "manager" ? null : (
    <Container fluid className="account-channel">
      <h2>
        {channel ? Texts.account.channel.edit : Texts.account.channel.new}
      </h2>
      <Segment className="account-channel-form">
        <Message negative>
          <p>{parse(Texts.account.channel.disclaimer)}</p>
        </Message>
        {navigationSelected === "info" ? (
          <AccountForm
            name="account-channel"
            formFields={AccountChannelForm}
            values={channelFormValues || parsedChannel}
            onSubmit={handleOnSubmit}
            texts={Texts.account.channel}
            provincesCities={provincesCities}
          />
        ) : null}
        {channel.id && navigationSelected === "audio" ? (
          <AccountChannelAudio
            channel_id={parseInt(channel.id)}
            media={channel.media}
            audio_clip={channel.audio_clip}
            mutate={mutate}
          />
        ) : null}
        {channel.id && navigationSelected === "gallery" ? (
          <AccountChannelGallery
            channel_id={parseInt(channel.id)}
            media={channel.media}
            audio_clip={channel.audio_clip}
            mutate={mutate}
          />
        ) : null}
      </Segment>
      <MessageModal
        open={open}
        texts={
          _.isEmpty(channel)
            ? Texts.account.channel.messages.success.edit
            : Texts.account.channel.messages.success.new
        }
        action={() =>
          (document.location.href = `${PathRoutes.PATH_ACCOUNT_MANAGER}/${slug}`)
        }
        onClose={() =>
          (document.location.href = `${PathRoutes.PATH_ACCOUNT_MANAGER}/${slug}`)
        }
      />
      <MessageModal
        open={openMedia}
        texts={Texts.account.channel.messages.success.media}
        action={() => setOpenMedia(false)}
        onClose={() => setOpenMedia(false)}
      />
      <AccountChannelPublishModal
        showPublishModal={showPublishModal}
        canPublish={canPublish}
        balance={balance}
        cities={getCities(channelFormValues?.city)}
        setShowPublishModal={setShowPublishModal}
        doSubmit={doSubmit}
      />
    </Container>
  );
};

AccountChannel.prototype = {
  channel: PropTypes.object,
  trigger: PropTypes.func,
  mutate: PropTypes.func,
};

export const AccountChannelNew = ({ navigationSelected }) => {
  const { trigger } = useChannelNew();
  return (
    <AccountChannel trigger={trigger} navigationSelected={navigationSelected} />
  );
};

export const AccountChannelEdit = ({ navigationSelected }) => {
  const { id } = useParams();

  const { channel, isLoading, mutate } = useChannelInmutable(id, true);
  if (_.isEmpty(channel) && !isLoading) {
    document.location.href = PathRoutes.PATH_ACCOUNT_MANAGER;
  }

  const { trigger } = useChannelUpdate(id);

  return !isLoading && !_.isEmpty(channel) ? (
    <AccountChannel
      channel={channel}
      trigger={trigger}
      mutate={mutate}
      navigationSelected={navigationSelected}
    />
  ) : null;
};
