const SubscribeButton = {
  tooltip: "Suscribirse",
  modal: {
    subscribe: {
      header: "Suscríbete",
      description: {
        header: "Únete a los más fans de ##__channel_alias__##",
        copy: "Accede a contenido exclusivo y obtén ventajas solo para suscriptores.",
        copy2: "Suscríbete ahora!",
      },
      actions: {
        cancel: "Cancelar",
        subscribe: "Sí, suscribirme",
      },
    },
    credits: {
      header: "Consigue créditos",
      description: {
        header: "Vaya, parece que no tienes créditos suficientes",
        copy: "Compra créditos para suscribirte al canal de ##__channel_alias__## y ver su contenido exclusivo y disfrutar de las ventajas de suscriptor.",
        copy2: "Compra créditos de forma rápida y segura.",
      },
      actions: {
        cancel: "Cancelar",
        buy: "Comprar créditos",
      },
    },
    autorenewal: {
      header: "¡Te has suscrito!",
      description: {
        header:
          "Ya puedes acceder a todo el contenido de ##__channel_alias__##",
        copy: "Recuerda que tu suscripción caducará en 30 días",
        copy2: "Gestiona tus suscripciones desde el panel de control",
        copy3:
          "¡Activa la autorenovación y olvídate de fechas!. La autorenovación te permite seguir disfrutando de las ventajas de ser suscriptor sin preocuparte*.",
        manage: "Gestionar suscripciones",
        disclaimer:
          "* La autorenovación se podrá activar y se hará efectiva al día siguiente de tu fecha de caducidad siempre y cuando haya créditos suficientes.",
      },
      actions: {
        cancel: "Cerrar",
      },
    },
  },
};

export default SubscribeButton;
