export const SessionSignup = {
  highlights: [
    "Monitoriza mejor a tu audencia",
    "Impulsa tu carrera",
    "Acceso a Salto",
    "Mentoría con profesionales",
    "Biblioteca del sexo",
    "Tienda y ventajas de creadores",
  ],
  features: [
    "Crea tu canal para publicar sin límite",
    "Ten acceso a todo el ludiverso del entretenimiento adulto",
    "Monetiza a tu audiencia",
    "Crea contenido épico con ayuda de profesionales",
  ],
  title:
    "Estás a un paso de darte de alta como creadora/creador del Nirvana para Adultos",
  form: {
    alias: { label: "Alias" },
    user: { label: "Email" },
    password: { label: "Contraseña" },
    password_repeat: { label: "Repite Contraseña" },
    name: { label: "Nombre" },
    surname: { label: "Apellidos" },
    phone: { label: "Teléfono" },
    phone2: { label: "Teléfono 2" },
    whatsapp: { label: "WhatsApp" },
    submit: { label: "Enviar" },
  },
  messages: {
    aliasError: {
      header: "El alias ya está en uso",
      list: [
        "Prueba con otro alias distinto",
        "##available__alias## está disponible",
      ],
    },
    formError: {
      header: "Error en el formulario",
      list: [
        "Comprueba que los datos están rellenos",
        "Comprueba que los datos son correctos",
      ],
    },
    success: {
      header: "Cuenta creada",
      content: [
        "Tu cuenta se ha creado correctamente",
        [
          "Ya puedes acceder con tu usuario",
          "Ya puedes acceder con tu contraseña",
          "Ya puedes crear anuncios",
        ],
      ],
      actions: {
        ok: "Iniciar sesión",
      },
    },
  },
};

export default SessionSignup;
