export const AccountWrapper = {
  button: {
    open: "Menu",
    close: "Cerrar",
  },
  menu: {
    profile: "Mis datos",
    subscriptions: "Mis suscripciones",
    channels: "Mis canales",
    wallet: "Mi cartera",
    info: "Datos del canal",
    thumbnails: "Vídeos y fotos de portada",
    audio: "Audio de portada",
    gallery: "Galería interior",
  },
};

export default AccountWrapper;
