import useSWRMutation from "swr/mutation";
import fetch from "unfetch";
import { API_SUB_ACTION } from "./endpoints";

export const useSubscriptionAction = () => {
  const url = API_SUB_ACTION;
  const fetcher = async (url, payload) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const responseData = await response.json();
        return responseData;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };
  return useSWRMutation(url, (url, { arg }) => {
    return fetcher(url, arg);
  });
};
