import React from "react";
import PropTypes from "prop-types";
import _ from "lodash/fp";
import { Form, FormField, TextArea, Button, Dropdown } from "semantic-ui-react";
import { useManager } from "../data/api/useManager";

import { Texts } from "../data/Texts";

const Comments = ({ id, comments, commentMessage, userData }) => {
  const { channels: managerChannels, isLoading } = useManager(userData?.alias);
  const channelsOptions = _.flow(
    _.map(({ idcanal, display_name }) => ({
      key: parseInt(idcanal),
      text: display_name,
      value: parseInt(idcanal),
    })),
    _.reject(({ value }) => !value)
  )(managerChannels);

  const setAutoChannel = (channelsLength, nullChannelId) => {
    if (channelsLength && nullChannelId) {
      commentMessage.handleCommentChannel(parseInt(managerChannels[0].idcanal));
    }
  };

  setAutoChannel(
    managerChannels?.length === 1,
    commentMessage.channelId === null
  );

  return (
    <div id={id || null} className="comments">
      <h2 className="comments-title">{Texts.comments.title}</h2>
      <ul className="comments-list">
        {_.map(({ id, by_type, comment_by, message }) => (
          <li className="comments-list-item" key={id}>
            <div className="comments-list-item-user">
              <img
                className="comments-list-item-user-avatar"
                src={comment_by.thumbnail}
                alt={comment_by.display_name}
              />
              {by_type === "channel" ? (
                <a href={`/${comment_by.slug}`}>
                  <h2 className="comments-list-item-user-name">
                    {comment_by.display_name}
                  </h2>
                </a>
              ) : (
                <h2 className="comments-list-item-user-name">
                  {comment_by.display_name}
                </h2>
              )}
            </div>
            <p className="comments-list-item-content">{message}</p>
            <div className="comments-list-item-actions">
              (edit, pin, like, etc...)
            </div>
          </li>
        ))(comments)}
      </ul>
      <div className="comments-new">
        <h6>
          {userData?.type === "manager" && managerChannels?.length > 1
            ? Texts.comments.new.titleManager
            : Texts.comments.new.title}
        </h6>
        {userData?.type === "manager" &&
        managerChannels?.length > 1 &&
        !isLoading ? (
          <Dropdown
            placeholder={Texts.comments.new.selectChannel}
            options={channelsOptions}
            onChange={(_e, { value }) => {
              commentMessage.handleCommentChannel(value);
            }}
          />
        ) : null}
        <Form>
          <FormField>
            <TextArea
              value={commentMessage.msg}
              onChange={(_e, data) =>
                commentMessage.handleCommentMessage(data.value)
              }
            />
          </FormField>
          <div className="comments-new-controls">
            <p
              className={`comments-new-controls-reminder ${commentMessage.status}`}
            >
              {commentMessage.count}/{commentMessage.max}
            </p>
            <Button
              primary
              onClick={() => commentMessage.handleCommentCreate()}
              disabled={
                !userData?.alias ||
                (userData?.type === "manager" && !commentMessage.channelId)
              }
            >
              {Texts.comments.new.send}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

Comments.propTypes = {
  comments: PropTypes.array,
  commentMessage: PropTypes.object,
  userData: PropTypes.object,
};

export default Comments;
