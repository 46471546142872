export const API_URL = process.env.REACT_APP_API_URL;

export const API_CHANNELS = `${API_URL}/canales`;
export const API_CHANNELS_CITIES_FILTERS = `${API_URL}/provsmunichannels`;
export const API_CHANNEL = `${API_URL}/canales/:id`;
export const API_CHANNEL_VIDEO = `${API_URL}/canales/video/:video_id`;
export const API_CHANNEL_EDIT = `${API_URL}/canales/edit/:id`;
export const API_CHANNEL_NEW = `${API_URL}/canales/new`;
export const API_CHANNEL_MEDIA = `${API_URL}/canales/media/:id`;
export const API_COMMENT_CREATE = `${API_URL}/comment/create`;
export const API_MANAGER = `${API_URL}/anunciantes/:id`;
export const API_MANAGER_EDIT = `${API_URL}/anunciantes/edit/:id`;
export const API_USER = `${API_URL}/users/:id`;
export const API_USER_EDIT = `${API_URL}/users/edit/:id`;
export const API_USER_ACTION = `${API_URL}/actions`;
export const API_USER_EDIT_AVATAR = `${API_URL}/users/edit/:id/avatar`;
export const API_SUB_ACTION = `${API_URL}/subs/action`;
export const API_LOGIN = `${API_URL}/login`;
export const API_PASSWORD_RECOVERY = `${API_URL}/password-recovery`;
export const API_SIGNUP = `${API_URL}/signup`;
export const API_SIGNUP_USER = `${API_URL}/users/add`;
export const API_SIGNUP_MANAGER = `${API_URL}/anunciantes/add`;
export const API_AUDIO_UPLOAD = `${API_URL}/audio/upload`;
export const API_AUDIO_EDIT = `${API_URL}/audio/edit/:id`;
export const API_MEDIA_UPLOAD = `${API_URL}/media/upload`;
export const API_MEDIA_EDIT = `${API_URL}/media/edit/:id`;
export const API_MEDIA_DELETE = `${API_URL}/media/delete/:id`;
export const API_CREDITS = `${API_URL}/credits`;
export const API_CREDITS_BUY = `${API_URL}/credits/buy`;
export const API_CREDITS_USE = `${API_URL}/credits/use`;
export const API_CREDITS_USER = `${API_URL}/credits/user/:alias`;
export const API_RATING_GET = `${API_URL}/rating/:resource_type/:resource`;
export const API_RATING_CREATE = `${API_URL}/rating/create`;
export const API_PROVINCES_CITIES = `${API_URL}/provsmuni`;
