import _ from "lodash/fp";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import useSWRImmutable from "swr/immutable";
import {
  API_CHANNEL,
  API_CHANNEL_EDIT,
  API_CHANNEL_NEW,
  API_CHANNEL_MEDIA,
} from "./endpoints";

export const useChannel = (id, translated = false) => {
  const url = API_CHANNEL.replace(":id", id);
  const fetcher = (url) => fetch(url).then((r) => r.json());
  const { data, error, isLoading, mutate } = useSWR(url, fetcher);
  // const channel = data ? data[0] : {};

  const translation = {
    thumbnail: "channelThumbnail",
    topVertical: "channelTopVertical",
    topHorizontal: "channelTopHorizontal",
    pictures: "",
    slug: "channel",
    descripcion_corta: "short_description",
    descripcion_larga: "description",
    etiquetas: "tags",
    pais: "country",
    ciudad: "city",
    zona: "zone",
    premium: "premium",
    in_top: "in_top",
    verified: "verified",
    total_follows: "follows",
    total_subs: "subs",
  };

  const channel = translated
    ? _.flow(
        _.mapKeys((key) => translation[key] || key), // Temporarily for translations
        _.omit(["undefined"])
      )(data?.[0])
    : data
    ? data[0]
    : {};

  return { channel, error, isLoading, mutate };
};

export const useChannelInmutable = (id, translated = false) => {
  const url = API_CHANNEL.replace(":id", id);
  const fetcher = (url) => fetch(url).then((r) => r.json());
  const { data, error, isLoading, mutate } = useSWRImmutable(url, fetcher);
  // const channel = data ? data[0] : {};

  const translation = {
    thumbnail: "channelThumbnail",
    topVertical: "channelTopVertical",
    topHorizontal: "channelTopHorizontal",
    pictures: "",
    slug: "channel",
    descripcion_corta: "short_description",
    descripcion_larga: "description",
    etiquetas: "tags",
    pais: "country",
    ciudad: "city",
    zona: "zone",
  };

  const channel = translated
    ? _.flow(
        _.mapKeys((key) => translation[key] || key), // Temporarily for translations
        _.omit(["undefined"])
      )(data?.[0])
    : data
    ? data[0]
    : {};

  return { channel, error, isLoading, mutate };
};

export const useChannelNew = () => {
  const url = API_CHANNEL_NEW;
  const fetcher = (url, payload) => {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((r) => {
      return r.json();
    });
  };
  return useSWRMutation(url, (url, { arg }) => {
    return fetcher(url, arg);
  });
};

export const useChannelUpdate = (id) => {
  const url = API_CHANNEL_EDIT.replace(":id", id);
  const fetcher = async (url, payload) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const responseData = await response.json();
        return responseData;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };
  return useSWRMutation(url, (url, { arg }) => {
    return fetcher(url, arg);
  });
};

export const useChannelMedia = (id) => {
  const url = API_CHANNEL_MEDIA.replace(":id", id);
  const fetcher = (url, payload) => {
    fetch(url, {
      method: "POST",
      body: payload,
    }).then((r) => {
      return r.json();
    });
  };
  return useSWRMutation(url, (url, { arg }) => {
    return fetcher(url, arg);
  });
};
